import React, { useState, useEffect } from "react";
import { I18nRails } from "../../../shared/rails-i18n-js";
import { Link, useLocation } from "react-router";
import * as rh from "../helpers/routes";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from 'tss-react/mui';
import { alpha, styled } from '@mui/material/styles';

import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { ListItemButton, Paper } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid2";
import { resetBasketTotals } from "../Model/basket";
import CartService from "../../services/CartService";
import { useSsrProps } from "../../SsrContext";
const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${ theme.spacing(4) }px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

const logoStyle = {
  paddingTop: 20,
};
const flexContainer = {
  display: "flex",
  flexDirection: "row",
  padding: 0,
  backgroundColor: "transparent",
  justifyContent: "flex-end",
  marginLeft: "60px",
};

const AppHeader = ({
  fndLocation,
  countryConfig,
  basket,
  user,
  handleAppSideNav,
  numberOfCartItems
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const [sidebar, setSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [locale, setLocale] = useState("en");

  const domLocation = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSwitcher = (e) => {
    document.cookie = `locale=${ e.target.value }`;
    localStorage.setItem("locale", e.target.value);
    window.location.reload();
  };

  const useStyles = makeStyles()(styles)
  const {classes, cx} = useStyles();
  useEffect(() => {
    try {
      const _locale = JSON.parse(localStorage.getItem("locale"));
      if (!!_locale) {
        setLocale(_locale);
      }
    } catch (e) {
      const _locale = localStorage.getItem("locale");
      if (!!_locale) {
        setLocale(_locale);
      }
    }
  }, []);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 9,
      top: -2,
      border: `2px solid black`,
      padding: "0 1px",
    },
  }));
  resetBasketTotals(basket, CartService.get(Rails));
  let domainlink = countryConfig.email;
  let phone = Rails.telto;

  return (
    (<AppBar position="fixed" style={{backgroundColor: '#212121'}}>
      <Grid container spacing={1} style={{padding: '10px 20px', background: '#000'}}>
        <Grid
          sx={{color: '#fff'}}
          size={{
            sm: 6
          }}>
          <a href={`mailto:${domainlink}`}>
            <i className="fas fa-envelope"></i>
            <span style={{marginRight: '10px', color: '#fff'}}>{' '+domainlink}</span>
          </a>
          {' '}
          <a href="tel:+92-300-0550831">
            <i className="fas fa-phone"></i>
            <span style={{color: '#fff'}}>{' '+phone}</span>
          </a>
        </Grid>
        <Grid
          className="languageswitcher"
          size={{
            sm: 6
          }}>
          <select value={locale} onChange={handleLanguageSwitcher}>
            <option value="en">English</option>
            <option value="ar">Arabic</option>
            <option value="ur">Urdu</option>
          </select>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{padding: '0px 20px'}}>
        <IconButton edge="start" onClick={handleAppSideNav} className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Grid
          sx={{display: { xs: 'none', sm: 'block' }}}
          style={{textAlign: 'center'}}
          className={"Item"}
          size={{
            sm: 2
          }}>
          <a style={logoStyle} href="/" className="brand-logo main-logo" />
        </Grid>
        <Grid
          className={"Item"}
          sx={{display: { xs: 'none', sm: 'block' }}}
          size={{
            sm: 9
          }}>
          <Grid>
            <List component="nav" aria-label="main mailbox folders" style={flexContainer}>
              <ListItem className="hides">
                {domLocation.pathname === '/' && (
                  <Link to="/locations">
                    <i id="hide" className="fas fa-utensils" />
                    <span className="inNav">{I18nRails.t("merchant.locations")}</span>
                  </Link>
                )}
              </ListItem>
              {!!fndLocation && fndLocation.pages ? (
                <>
                  <ListItemButton className="hides">
                    <Link to={rh.location_blogs({location_id: fndLocation.slug}, Rails)}>
                      <i className="fas fa-rss" />
                      <span className="inNav">{I18nRails.t("business_dashboard.footer.blog")}</span>
                    </Link>
                  </ListItemButton>
                  <ListItemButton className="hides">
                    <Link to={rh.location_reviews({location_id: fndLocation.slug}, Rails)}>
                      <i className="fas fa-rss" />
                      <span className="inNav">{I18nRails.t("business_dashboard.footer.reviews")}</span>
                    </Link>
                  </ListItemButton>
                </>
              ): (
                <ListItemButton className="hides">
                  <Link to="/blogs">
                    <i className="fas fa-rss" />
                    <span className="inNav">{I18nRails.t("business_dashboard.footer.blog")}</span>
                  </Link>
                </ListItemButton>
              )}
              <ListItem className="hides">
                {basket && basket.location_id ? (
                  <Link
                    style={{cursor: 'pointer'}}
                    to={rh.location_cart({location_id: basket.location_id}, Rails)}
                  >
                    <StyledBadge badgeContent={basket.totals.numberOfCartItems} color="error">
                      <i className="fas fa-shopping-cart" />
                    </StyledBadge>
                    <span className="inNav">{I18nRails.t("Web.shopping.cart")}</span>
                  </Link>
                ) : (
                  <Link style={{cursor: 'pointer'}} to="/cart">
                    <StyledBadge badgeContent={numberOfCartItems} color="error">
                      <i className="fas fa-shopping-cart" />
                    </StyledBadge>
                    <span style={{textTransform: 'capitalize'}}>{I18nRails.t("Web.shopping.cart")}</span>
                  </Link>
                )}
              </ListItem>
              {!!user ? (
                <ListItem className="hides">
                  <Link to='/users/me' style={{cursor: 'pointer'}}>
                    <i className="fas fa-user"></i>
                    <span className="inNav">{user && user.first_name}</span>
                  </Link>
                </ListItem>
              ) : (
                <ListItem className="hides">
                  <Link to='/login' style={{cursor: 'pointer'}}>
                    <i className="fas fa-user"></i>
                    <span className="inNav">{I18nRails.t("Web.login")}</span>
                  </Link>
                </ListItem>
              )}
              <ListItem className="center hides contact-help">
                <h6>
                  <b>{I18nRails.t("Web.help")}{' '}</b>
                  <a onClick={handleClick}>
                    <i className="fas fa-caret-down"></i>
                  </a>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem>
                      <a href={"mailto:" + countryConfig.email} target="_top">
                        <i className="fas fa-envelope"></i>
                        {"hello@foodnerd.pk"}
                      </a>
                    </MenuItem>
                  </Menu>
                </h6>
                <a href={"tel:" + countryConfig.telto} style={{fontSize: '13px'}}>
                  <i className="fas fa-phone phone-call"></i>
                  {countryConfig.telto}
                </a>                    
              </ListItem>
              {domLocation.pathname === '/' && (
                <li className="downloadBtn">
                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.menumonkey.app&hl=en&gl=US"
                    style={{margin: "20px 0px 0px 0px"}}
                    className="white-text button clr-down"
                  >
                    {I18nRails.t("home.get_app")}
                  </Button>
                </li>
              )}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>)
  );
};

export default AppHeader;
